  <template>
  <div>
    <div class="row">
      <div class="mb-3 col">
        <label for="exampleFormControlInput1" class="form-label">SBC Name</label>
        <input type="text" class="form-control" v-model="item.sbc" />
      </div>
      <div class="mb-3 col">
        <label for="exampleFormControlInput1" class="form-label">Protocol</label>
        <input type="text" class="form-control" v-model="item.protocol" />
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col">
        <label for="exampleFormControlInput1" class="form-label">SBC IPs</label>
        <input type="text" class="form-control" v-model="item.ips" />
      </div>
      <!-- <div class="mb-3 col">
        <label for="exampleFormControlInput1" class="form-label">Status</label>
        <input type="text" class="form-control" v-model="item.status" />
      </div> -->
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn btn-success" @click="submit()">Submit</button>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      item: {
        sbc: null,
        protocol: null,
        ips: null,
      },
    }
  },
  methods: {
    submit() {
      this.$router.push('/verif-whitelist')
    },
  },
}
</script>
